import { MainContainer } from '~/components';
import * as React from 'react';
import { useTheme, css, jsx, keyframes } from '@emotion/react';
import { BottomBar, CategoryList } from '../components/mobile';
import { Logo, Button, Link, SEO } from '~/components';
import { RollIn } from '~/components/animations';
import Swiper from 'react-id-swiper';
import { useStore } from '../provider/StoreProvider';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { useApp } from '../provider/AppProvider';
import { FaInstagram } from 'react-icons/fa';

const BEZIER = 'cubic-bezier(0.820, 0.000, 0.275, 1.005)';

const bounce = keyframes`
  0%, from {
    transform: translate3d(0,60px,0);
    opacity: 0;
  }

  5%, 33.33% {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }

  38.33%, to {
    transform: translate3d(0, -60px, 0);
    opacity: 0;
  }

`;

const heroContainer = css({
  position: 'relative',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export default function Home() {
  const theme = useTheme();
  const { setHeaderVisible } = useApp();
  const { store, featuredProducts } = useStore();
  const { collections } = store;
  const [isMounted, setIsMounted] = React.useState(false);

  const videoRef = React.useRef();

  React.useEffect(() => {
    if (videoRef.current) {
      const timeoutId = setTimeout(() => videoRef.current?.play(), 2000);
      return () => clearInterval(timeoutId);
    }
  }, []);

  React.useEffect(() => {
    setIsMounted(true);

    const timeOut = setTimeout(
      () => setHeaderVisible(true),
      window.scrollY > 0 ? 0 : 3000
    );
    return () => clearTimeout(timeOut);
  }, []);

  function renderFeaturedProducts() {
    if (featuredProducts.length > 0) {
      return (
        <section
          css={{
            margin: theme.spacing(10, 0),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2
            css={{
              color: theme.colors.sand,
              textTransform: 'uppercase',
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            Eusi empfehlig
          </h2>

          <MainContainer
            css={{
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
              marginLeft: theme.spacing(-2),
              marginTop: theme.spacing(-2),
            }}
          >
            {featuredProducts.map((prod, idx) => (
              <Link
                css={{
                  marginLeft: theme.spacing(2),
                  marginTop: theme.spacing(2),
                  backgroundColor: theme.colors.sand,
                  flex: 1,
                  position: 'relative',
                  overflow: 'hidden',
                  flexBasis: 200,
                }}
                to={`/${prod.collection.handle}/${prod.handle}`}
              >
                <GatsbyImage
                  image={
                    prod.images[0].localFile.childImageSharp.gatsbyImageData
                  }
                  alt={prod.title}
                  objectFit="cover"
                  css={{
                    width: '100%',
                    height: '100%',
                  }}
                />
                <div
                  css={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: `${theme.colors.sand}70`,
                    padding: theme.spacing(6),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.colors.green,
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    lineHeight: '2.5rem',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  {prod.title}
                </div>
              </Link>
            ))}
          </MainContainer>
          <Button
            css={{ marginTop: theme.spacing(2) }}
            text="Alli azeige"
            to="/menuecharte"
          />
        </section>
      );
    }
    return null;
  }

  function renderFollowUs() {
    return (
      <section>
        <MainContainer
          css={{
            marginTop: theme.spacing(10),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.colors.sand,
          }}
        >
          <h5
            css={{
              textAlign: 'center',
              lineHeight: '2rem',
              textTransform: 'uppercase',
              color: theme.colors.rose,
              marginBlockEnd: '0rem',
            }}
          >
            Bliib uf em laufende
          </h5>
          <h3
            css={{
              marginBlockStart: '0rem',
              textAlign: 'center',
              lineHeight: '2rem',
              textTransform: 'uppercase',
            }}
          >
            Folg eus uf Instagram
          </h3>
          <a
            href="https://www.instagram.com/nahschub/"
            target="_blank"
            css={{
              border: `2px solid ${theme.colors.sand}`,
              borderRadius: 1000,
              width: 44,
              height: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: '0.5s',
              color: theme.colors.sand,
              '&:hover': {
                backgroundColor: theme.colors.sand,
                color: theme.colors.green,
              },
            }}
          >
            <FaInstagram size={20} />
          </a>
        </MainContainer>
      </section>
    );
  }

  function renderDeliveryInfo() {
    return (
      <section
        css={{
          minHeight: '100vh',
          backgroundColor: theme.colors.sand,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          css={{
            padding: theme.spacing(6),
          }}
        >
          <StaticImage
            src="../../static/images/delivery.jpg"
            alt="wein"
            css={{
              height: `calc(100vh - 200px)`,
              width: 'auto',
            }}
            objectFit="cover"
          />
        </div>
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <h1
            css={{
              color: theme.colors.sand,
              textAlign: 'center',
              textShadow: `0px 0px 4px ${theme.colors.green}`,
              textTransform: 'uppercase',
              padding: theme.spacing(0, 4),
            }}
          >
            Züri Kreis 1 – 10
            <br />
            Gratis Lieferig ab CHF 59.-
          </h1>
          <Button text="Meh infos" to="/lieferinformationen" />
        </div>
      </section>
    );
  }

  function renderLogoAppearance() {
    return (
      <div css={{ zIndex: 200, overflow: 'hidden' }}>
        <Logo
          width={200}
          css={{
            transition: '1s',
            transform: `translateY(${isMounted ? 0 : 150}%)`,
            transitionTimingFunction: BEZIER,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <SEO
        title="Home"
        description="Mir liefered dir din Lieblingsdrink innert 30 Minute - egal wod bisch."
      />
      <div
        css={{
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <section css={heroContainer}>
          {/* {renderLogoAppearance()} */}

          {/* <RollIn
          show={isMounted}
          transitionProps={{
            transitionDelay: '1s',
          }}
        >
          <StaticImage
            src="../../static/images/wein.jpg"
            alt="drinks"
            css={{ width: '100%', height: '100%' }}
            objectFit="cover"
          />
        </RollIn> */}

          {/* <RollIn
          show={isMounted}
          color="red"
          transitionProps={{
            transitionDelay: '2s',
          }}
        >
          <StaticImage
            src="../../static/images/drinks.jpg"
            alt="wein"
            css={{ width: '100%', height: '100%' }}
            objectFit="cover"
          />
        </RollIn> */}

          <RollIn
            show={isMounted}
            transitionProps={{
              transitionDelay: '0s',
            }}
          >
            <StaticImage
              src="../../static/images/lette.jpg"
              alt="wein"
              css={{ width: '100%', height: '100%' }}
              objectFit="cover"
            />

            {/* <video muted playsinline loop autoplay ref={videoRef}>
            <source
              src="https://firebasestorage.googleapis.com/v0/b/test-nahschub.appspot.com/o/DJI_1327.MP4?alt=media&token=f8842b5c-508d-492f-9b88-9de136031ef4"
              type="video/mp4"
            />
          </video> */}
          </RollIn>

          <div
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              // opacity: isMounted ? 1 : 0,
              // transitionDelay: '3s',
              padding: theme.spacing(8),
              // backgroundColor: `${theme.colors.sand}50`,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                paddingBottom: theme.spacing(0, 4, 2, 4),
                maxWidth: '800px',
                textAlign: 'center',
              }}
            >
              <h1
                css={(theme) => ({
                  color: theme.colors.sand,
                  transform: `translateY(${isMounted ? 0 : 60}px)`,
                  opacity: isMounted ? 1 : 0,
                  transitionDelay: '1s',
                  transitionDuration: '0.6s',
                  transitionTimingFunction: BEZIER,
                  textShadow: `0 0 16px ${theme.colors.green}40`,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '1.5rem',
                    lineHeight: '1.8rem',
                  },
                })}
              >
                Mir liefered Dir Din Lieblingsdrink innerhalb vo{` `}
                <span css={(theme) => ({ color: theme.colors.rose })}>
                  30Min
                </span>
              </h1>

              <div
                css={{
                  height: 120,
                  position: 'relative',
                  marginBottom: theme.spacing(5),
                  width: '100%',
                }}
              >
                {['AN LETTE', 'AD RENTE', 'WOD GRAD BISCH'].map(
                  (place, idx) => (
                    <div
                      key={place}
                      css={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <h2
                        css={css`
                          animation: ${bounce} 7s ease ${idx * (7 / 3) + 1.8}s
                            infinite;
                          font-size: 3.5rem;
                          line-height: 4rem;
                          color: ${theme.colors.sand};
                          transform: translate3d(0, 60px, 0);
                          opacity: 0;
                          text-shadow: 0 0 16px ${theme.colors.green}40;
                          @media (max-width: ${theme.breakpoints.sm}px) {
                            font-size: 2.5rem;
                            line-height: 3rem;
                          }
                        `}
                      >
                        {place}
                      </h2>
                    </div>
                  )
                )}
              </div>
            </div>
            <Button
              variant="sand"
              outline={false}
              to="/menuecharte"
              text="Zu de Menücharte"
              css={{
                opacity: isMounted ? 1 : 0,
                transitionDuration: '0.6s',
                transitionDelay: '7.5s',
                transitionTimingFunction: BEZIER,
              }}
            />
          </div>
        </section>

        {renderFeaturedProducts()}

        {renderDeliveryInfo()}

        {renderFollowUs()}

        {/* <MainContainer>
        <div>
          <h1
            css={{
              color: theme.colors.rose,
              textTransform: 'uppercase',
              textAlign: 'center',
              paddingBottom: theme.spacing(5),
              paddingTop: theme.spacing(5),
            }}
          >
            Menücharte
          </h1>
          <div></div>
          {collectionsList.map((category, idx) => {
            return (
              <CategoryList
                ref={sectionRefList.current[idx]}
                category={category}
                key={category}
              />
            );
          })}
        </div>

        <BottomBar
          categories={collectionTitlesList}
          categoriesSectionRefList={sectionRefList}
        />
      </MainContainer> */}
      </div>
    </>
  );
}
